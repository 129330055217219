import React from "react";
import PropTypes from "prop-types";

export default function Tag({ estado }) {
  return (
    <div className="w-1/2">
      <div
        style={{
          backgroundColor:
            estado === "EmAndamento"
              ? "#8B7DFF"
              : estado === "Lancamento"
              ? "#D27A34"
              : estado === "EmConstrucao"
              ? "#ddd10c"
              : estado === "Pronto" || estado === "ObraConcluida"
              ? "#00AD8F"
              : "#C35257",
          borderRadius: "18px",
          opacity: 1,
          padding: "box",
          height: "28px",
        }}
      >
        <div className="tag" style={{paddingTop:'0.3rem'}}>
          {estado === "EmAndamento"
            ? "EM ANDAMENTO"
            : estado === "Lancamento"
            ? "LANÇAMENTO"
            : estado === "Pronto"
            ? "PRONTO PARA MORAR"
            : estado === "ObraConcluida"
            ? "OBRA CONCLUIDA"
            : estado === "EmConstrucao"
            ? "EM CONSTRUÇÃO"
            : "VENDIDO"}
        </div>
      </div>
    </div>
  );
}

Tag.propTypes = {
  estado: PropTypes.any,
};
