import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { url } from "../../api";
import Tag from "../../components/tag";

export default function Card({ building }) {
  return (
    <Link to={`/predio?id=${building?.id}`}>
      <img
        className="img-zoom mb-4"
        src={`${url}${building?.cartao?.url}`}
        alt={building?.cartao?.url}
        height="100"
      />
      <Tag estado={building?.estado} />
      <h2>{building?.nomePredio}</h2>
      <p>{building?.enderecoCheio?.cidade}</p>
    </Link>
  );
}
Card.propTypes = {
  building: PropTypes.any,
};
